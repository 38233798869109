<template>
  <div class="container mescroll-touch">
    <div class="header">
      <div class="searchBox" @click="toSearch()">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
      </div>
    </div>
    <div class="contentBox">
      <div class="banner" v-if="bannerList.length > 0">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#CA001B">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index" @click="imgclick(item.UrlLink)">
            <img class="bannerImg" :src="item.File_Url" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- <div class="menuBox" v-if="!showStayTuned">
        <div class="menuItem" :class="{'active':  tcode == item.TCode}" v-for="(item, index) in columnList" :key="index" @click="changeMenu(item.TCode)">
          <img class="menuIcon menuIcon1" v-if="item.activeIcon && item.icon" :src="tcode == item.TCode ? item.activeIcon : item.icon" />
          <span>{{item.DisplayName}}</span>
        </div>
      </div> -->
      <div class="listBox" v-show="list.length > 0">
        <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
          <div class="list" id="dataList">
            <div class="item" v-for="(item, index) in list" :key="index" @click="toContentPage(item.Id, item.Type)">
              <div class="item_img">
                <img class="item_cover" :src="item.Thumbnail" />
                <img class="playIcon" v-if="item.Type == 1 || item.Type == 3" src="@/assets/images/play_comm_icon@2x.png" />
              </div>
              <div class="item_content">
                <span class="item_title">{{item.Title}}</span>
                <div class="item_flexbox">
                <p class="item_time">{{item.yyyymmdd}}</p>
                <div class="item_nums">
                  <div class="nums">
                    <img class="watchIcon" src="@/assets/images/watch_yellow.png" />
                    <span>{{item.WatchCount}}</span>
                  </div>
                  <div class="nums">
                    <img class="likeIcon" src="@/assets/images/like_yellow.png" />
                    <span>{{item.LikeCount}}</span>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </mescroll-vue>
      </div>
      <div class="stayTuned" v-if="showStayTuned">
        <img src="@/assets/images/none_comm_png@2x.png" />
        <p class="describe" v-if="code == 'cnvczqs'">CNV卒中强声城市会，将为您提供一个更专业讨论卒中疾病诊疗热点和难点的学术交流平台。</p>
        <p class="describe" v-if="code == 'cnvlive'">CNV LIVE，将为您提供一个动脉瘤疾病诊治手术全流程直播平台，更好地进行技术即刻交流。</p>
        <p class="stayTuned_font">更多详情，敬请期待</p>
      </div>
    </div>
  </div>
</template>

<script>
import MescrollVue from 'mescroll.js/mescroll.vue'
export default {
  name: 'CNVLive',
  data() {
    return {
      enterpriseBg: require('../../assets/images/enterprise_bg.png'),
      enterpriseBg1: require('../../assets/images/enterprise_bg1.png'),
      headerBg: require('../../assets/images/header_bg.png'),
      headerBg1: require('../../assets/images/header_bg1.png'),
      bannerList: [],
      currentIndicator: 0,
      isBanner: false,
      columnList: [],
      tcode: '',
      list: [],
      PageIndex: 0,
      PageSize: 6,
      mescroll: null, // mescroll实例对象
      mescrollDown:{
        use: false,
        isLock: false
      },
      mescrollUp: { // 上拉加载的配置.
        isBounce: false,
			  callback: this.upCallback, // 上拉回调
				page: {
					num: 0, //当前页 默认0,回调之前会加1; 即callback(page)会从1开始
					size: 6 //每页数据条数,默认10
				},
        noMoreSize: 0,
				htmlNodata: '<p class="upwarp-nodata"></p>',
        toTop: {
          warpId : 'toTop',
          src: './static/image/top_comm_icon@2x.png' // 回到顶部按钮的图片路径,支持网络图
        },
        empty: {
          // 列表第一页无任何数据时,显示的空提示布局; 需配置warpId才生效;
          warpId: 'dataList', // 父布局的id;
          icon: './static/image/none_comm_png@2x.png', // 图标,支持网络图
          tip: '敬请期待', // 提示
        }
			},
      scrollTop: 0,
      showStayTuned: false
    }
  },
  components: {
    MescrollVue
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  created() {
    if(this.code) {
      this.getBanner();
      this.getColumn();
    }
  },
  activated() {
    this.$refs.mescroll.$el.scrollTop = this.scrollTop;
  },
  methods: {
    // 获取Banner
    getBanner() {
      this.$axios.get('/Api/api/Web/ImgFile/GetImgFileListByCode?Code=' + this.code).then(res => {
        this.bannerList = res.Data;
        if(this.bannerList.length > 0) {
          this.isBanner = true;
        }
      })
    },
    // 获取栏目
    getColumn() {
      this.$axios.get('/Api/api/Web/Article/GetCode?code=' + this.code).then(res => {
        let data = res.Data;
        if(data.length > 0) {
          this.tcode = data[0].TCode;
        }
        this.columnList = data;
      })
    },
    // 获取列表数据
    getList(PageIndex, PageSize) {
      this.PageIndex = PageIndex;
      this.PageSize = PageSize;
      setTimeout(() => {
        this.$axios.post('/Api/Api/Web/Article/GetArticleList', {
          SubjectTCode: this.code + '|',
          PageIndex: this.PageIndex,
          PageSize: this.PageSize
        }).then((res) => {
          let arr = [];
          if(res.Data.List.length > 0) {
            arr = res.Data.List;
          } else {
            this.showStayTuned = true;
          }
          // 如果是第一页需手动置空列表
          if (PageIndex === 1) this.list = []
          // 把请求到的数据添加到列表
          this.list = this.list.concat(arr);
          // 数据渲染成功后,隐藏下拉刷新的状态
          this.$nextTick(() => {
            this.mescroll.endSuccess(arr.length)
          })
        }).catch((e) => {
          // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
          this.mescroll.endErr()
        })
      }, 200);
    },
    // mescroll组件初始化的回调,可获取到mescroll对象
		mescrollInit (mescroll) {
			this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
		},
    // 上拉回调
		upCallback (page) {
      this.getList(page.num, page.size);
		},
    imgclick(UrlLink) {
      let objExp=new RegExp(/^(f|ht)tps?:\/\//i);
      if(UrlLink) {
				if(objExp.test(UrlLink)) {
				  location.href = UrlLink;
				}else{
					this.$router.push(UrlLink)
				}
      }
    },
    onSwipeChange(index) {
      this.currentIndicator  = index;
    },
    indicatorChange(index) {
      this.currentIndicator  = index;
      this.$refs.vanSwipe.swipeTo(index);
    },
    changeMenu(tcode) {
      this.tcode = tcode;
      this.list = [];
      this.mescroll.resetUpScroll() // 刷新列表数据
    },
    toSearch() {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'search',
        query: {
          code: this.code
        }
      });
    },
    toContentPage(Id, Type) {
      this.scrollTop = this.$refs.mescroll.$el.scrollTop;
      this.$router.push({
        path: 'contentPage',
        query: {
          id: Id,
          type: Type
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url('../../assets/images/bg_comm_png.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .header {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 15px 15px 10px;
    box-sizing: border-box;
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 35px;
      line-height: 35px;
      border-radius: 35px;
      background-color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      padding: 0 15px;
      margin-bottom: 10px;
      .searchIcon {
        width: 15px;
        height: 15px;
      }
      .searchInput {
        flex: 1;
        margin-left: 8px;
        border: none;
        color: #999999;
      }
    }
    .bannerBox {
      border-radius: 8px;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
      margin-top: 15px;
      .bannerImg {
        width: 100%;
        display: block;
        border-radius: 8px;
      }
    }
  }
  .contentBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
    background-color: #F8F8F8;
    border-radius: 12px 12px 0 0;
  }
  .banner {
    margin: 15px 15px 0;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 0px;
    overflow: hidden;
    .bannerImg {
      width: 100%;
      display: block;
      border-radius: 10px;
    }
  }
  .menuBox {
    display: flex;
    // justify-content: space-between;
    padding: 15px 15px;
    box-sizing: border-box;
    .menuItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32%;
      height: 32px;
      font-size: 14px;
      border-radius: 8px;
      border: 1px solid $themeColor;
      background-color: #FFFFFF;
      box-sizing: border-box;
      .menuIcon {
        width: 22px;
        margin-right: 2px;
      }
      .menuIcon1 {
        width: 18px;
      }
    }
    .menuItem:not(:last-child) {
      margin-right: 4%;
    }
    .active {
      border: none;
      background-color: $themeColor;
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .listBox {
    margin-top: 20px;
    flex: 1;
    height: 0;
    .list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 15px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: calc(calc(100% / 2) - 5px);
        border-radius: 6px;
        margin-bottom: 10px;
        background-color: #FFFFFF;
        overflow: hidden;
        .item_img {
          position: relative;
          .item_cover {
            display: block;
            width: 100%;
          }
          .playIcon {
            position: absolute;
            right: 12px;
            bottom: 12px;
            width: 30px;
            height: 30px;
          }
        }
        .item_content {
          padding: 5px 6px 6px;
          .item_title {
            height: 35px;
            font-size: 13px;
            line-height: 17px;
            color: #333333;
            overflow: hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;
            word-wrap: break-word;
          }
          .item_time {
            font-size: 12px;
            color: #999999;
            line-height: 22px;
          }
          .item_label {
            display: flex;
            flex-wrap: wrap;
            span {
              display: inline-block;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              padding: 0 6px;
              margin-right: 6px;
              margin-bottom: 5px;
              background-color: rgba(255, 73, 98, 0.15);
              font-size: 11px;
              color: #CA001B;
            }
          }
          .item_nums {
            display: flex;
            justify-content: flex-end;
            .nums {
              display: flex;
              align-items: center;
              .watchIcon {
                width: 14px;
                margin: 0 2px 0 6px;
              }
              .likeIcon {
                width: 11px;
                margin: 0 2px 0 6px;
              }
              span {
                font-size: 11px;
                color: #666666;
              }
            }
          }
        }
      }
      .item:nth-child(even) {
        margin-left: 10px;
      }
    }
  }
  .stayTuned {
    text-align: center;
    color: gray;
    font-weight: bold;
    img {
      width: 45%;
      margin-top: 10px;
    }
    .describe {
      width: 70%;
      margin: 20px auto;
      font-size: 12px;
      line-height: 18px;
    }
    .stayTuned_font {
      font-size: 12px;
    }
  }
}
</style>